<template>
  <hospitals-module-component></hospitals-module-component>
</template>

<script>
import HospitalsModuleComponent from "@/components/admin/modules/HospitalsModuleComponent";

export default {
  name: "HospitalsModule",
  title: "Gestión de Hospitales | Baja California Health Tourism",
  components: { HospitalsModuleComponent },
};
</script>

<style scoped></style>
